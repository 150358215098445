"use client";

import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";
const MarkdownParser = ({
  children
}) => {
  if (typeof children !== "string") {
    return null;
  }
  return <Markdown options={{
    disableParsingRawHTML: true
  }} data-sentry-element="Markdown" data-sentry-component="MarkdownParser" data-sentry-source-file="index.jsx">{children}</Markdown>;
};
MarkdownParser.propTypes = {
  children: PropTypes.string
};
export default MarkdownParser;