import { routes } from ".";

export const routeForDocument = ({_type = "", slug = { current: "" }}: IProps) => {
	const formattedSlug = typeof(slug) === "string" ?  slug : slug?.current;
	if (routes[_type]) {
		return routes[_type](formattedSlug);
	}
	console.warn(`The page type '${_type}' doesn't have a route associated with it.`);
	return null
}

interface IProps {
	_type: string,
	slug?: {current: string} | string
}

