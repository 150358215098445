import { routeForDocument } from "@metmuseum/routes";
export * from "@metmuseum/routes";

export const routeForDocumentWithSubsection = ({ _type = "", slug = { current: "" }, subsection = "" }) => {
	// For now, do the naive implementation. Correctness will be checked on the CMS side
	return `${routeForDocument({ _type, slug })}${subsection ? subsection : ''}`;
}

/*
	Attempting to keep track of all research-center sub pages.
	With the updated "person group" template - there is now the possibility of custom routes
	that pull for the Role Slug field. pass "AllowCustom" to override the default behavior
*/
export const researchCenterRoutes = (slug = "", page, allowCustom = false) => {
	const baseRoute = routeForDocument({ _type: "researchCenter", slug: { current: slug } });
	switch (page) {
		case 'team':
			return `${baseRoute}/team`;
		case 'advisory-committee':
			return `${baseRoute}/advisory-committee`;
		case 'distinguished-scholars':
			return `${baseRoute}/distinguished-scholars`;
		case 'fellowships':
			return `${baseRoute}/fellowships`;
		case 'fellows':
			return `${baseRoute}/fellows`;
		case 'publication-grants':
			return `${baseRoute}/publication-grants`;
		case `research-resources`:
			return `${baseRoute}/research-resources`;
		case `curator-and-conservator-residencies`:
			return `${baseRoute}/curator-and-conservator-residencies`;
		default:
			return allowCustom ? `${baseRoute}/${page}` : baseRoute;
	}
}
